export class FetchWorkflow {
  static readonly type = '[Workflow] Retrieve Workflow by Id';
  constructor(public workflowId: string) {}
}

export class FetchInjectedScreen {
  static readonly type = '[Workflow] Retrieve Injectable Screen';
  constructor(public screenId: string) {}
}

export class FetchScreenById {
  static readonly type = '[Workflow] Retrieve Screen by Id';
  constructor(public screenId: string) {}
}

export class FetchServiceView {
  static readonly type = '[Workflow] Retrieve Service View';
  constructor(public screenId: string) {}
}

export class ResetScreens {
  static readonly type = '[Workflow] Reset Screens';
  constructor() {}
}

export class ResetWorkflow {
  static readonly type = '[Workflow] Reset Workflow';
  constructor() {}
}

export class SetStep {
  static readonly type = '[Workflow] Set Step';
  constructor(public stepId: string) {}
}

export class SetInitialStep {
  static readonly type = '[Workflow] Set Initial Step';
  constructor() {}
}

export class SetNextStep {
  static readonly type = '[Workflow] Evaluate Step Condition';
  constructor(public condition: unknown = null) {}
}
